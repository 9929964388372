export class LocalData {
  setWalletSelected(walletSelected: string) {
    if (walletSelected) {
      localStorage.setItem('walletSelected', walletSelected)
    } else {
      localStorage.removeItem('walletSelected')
    }
  }

  getActiveWalletAddress() {
    return localStorage.getItem('activeWalletAddress') || ''
  }

  setActiveWalletAddress(walletAddress: string) {
    if (walletAddress) {
      localStorage.setItem('activeWalletAddress', walletAddress)
    } else {
      localStorage.removeItem('activeWalletAddress')
    }
  }

  getAccessToken() {
    return localStorage.getItem('accessToken') || ''
  }

  setAccessToken(walletAddress: string) {
    if (walletAddress) {
      localStorage.setItem('accessToken', walletAddress)
    } else {
      localStorage.removeItem('accessToken')
    }
  }

  getWalletSelected() {
    return localStorage.getItem('walletSelected') || ''
  }

  get walletConnect() {
    return localStorage.getItem('walletconnect')
  }

  removeWalletConnect() {
    localStorage.removeItem('walletconnect')
  }
}

export const localData = new LocalData()
