import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 0,
      sm: 700,
      md: 1320,
      lg: 1850,
    },
  },
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: {
          base: '#D6F76B',
        },
        'algo-grey': {
          base: '#5C5C5C',
          lighten1: '#8D8D8D',
          lighten2: '#F5F6F8',
          lighten3: '#1F1F1F',
          darken1: '#262626',
          darken2: '#111111',
          darken3: '#FFFFFF',
          darken4: '#C4C4C4',
        },
        'gradient-banner': {
          base: '#7DDBFB',
          lighten1: '#98E9C8',
          lighten2: '#BBF28E',
          lighten3: '#D5F86D',
        },
        'footer-project-card': {
          lighten1: '#AFF87D',
          lighten2: '#7CD6B3',
          lighten3: '#6EBBFC',
          lighten4: '#7B88FF',
          lighten5: '#FF426F',
        },
      },
      dark: {
        primary: {
          base: '#D6F76B',
        },
        'algo-grey': {
          base: '#5C5C5C',
          lighten1: '#8D8D8D',
          lighten2: '#F5F6F8',
          lighten3: '#1F1F1F',
          darken1: '#262626',
          darken2: '#111111',
          darken3: '#FFFFFF',
          darken4: '#C4C4C4',
        },
        'gradient-banner': {
          base: '#7DDBFB',
          lighten1: '#98E9C8',
          lighten2: '#BBF28E',
          lighten3: '#D5F86D',
        },
        'footer-project-card': {
          base: '#D6F76B',
          lighten1: '#AFF87D',
          lighten2: '#7CD6B3',
          lighten3: '#6EBBFC',
          lighten4: '#7B88FF',
          lighten5: '#FF426F',
        },
        error: '#FF426F',
      },
    },
  },
})
