


















import { Component, Vue, Provide } from 'vue-property-decorator'
import { AppProvider, appProvider } from './app-provider'
import { walletStore } from './stores/wallet-store'

@Component({
  components: {
    'snack-bar': () => import('@/components/snack-bar/snack-bar.vue'),
    'navigation-bar': () => import('@/components/navbar/navigation-bar.vue'),
    'account-detail-dialog': () => import('@/components/navbar/account-detail-dialog.vue'),
    'connect-wallet-dialog': () => import('@/components/navbar/connect-wallet-dialog.vue'),
    'drawer-bar': () => import('@/components/navbar/drawer.vue'),
  },
})
export default class App extends Vue {
  @Provide() providers: AppProvider = appProvider
  wallet = this.providers.wallet

  drawerDialog = false

  mounted() {
    this.providers.router = this.$router
    walletStore.start()
  }
}
