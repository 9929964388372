import { action, computed, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import MyAlgoConnect from '@randlabs/myalgo-connect'
import { localData } from './local-data'
import algosdk, { Algodv2 } from 'algosdk'
import { apiService } from '@/services/api-services'
import { InvestorModel } from '@/models/investor-model'
import SynapsClient from '@synaps-io/verify.js'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { encode, decode } from 'uint8-to-base64'
import WalletConnect from '@walletconnect/client'
import QRCodeModal from 'algorand-walletconnect-qrcode-modal'
import { formatJsonRpcRequest } from '@json-rpc-tools/utils'
import { UserModel } from '@/models/user-model'
import { appProvider } from '@/app-provider'
import { checkDeviceType } from '@/helper/ua-parser'
import { AlgoClient } from '@/algo-client'

export const connector = new WalletConnect({
  bridge: 'https://bridge.walletconnect.org',
  qrcodeModal: QRCodeModal,
})
export class WalletStore {
  @observable detailDialog = false
  @observable connectDialog = false

  @observable account = ''
  @observable selectedWallet = ''
  @observable showConnectDialog = false
  @observable chainId = 4160
  @observable userWallet = undefined
  @observable jwt = ''
  algodClient?: Algodv2 = undefined
  @observable loadingKycState = false
  @observable sessionId: any = ''
  @observable isLoadingSignIn = false
  @observable userInfo?: UserModel = undefined
  @observable investor?: InvestorModel = undefined
  @observable loading = false
  @observable connectingWallet = false
  @observable synapsClient: any = undefined

  constructor() {
    this.algodClient = AlgoClient
    reaction(
      () => this.account,
      async (account) => {
        const walletExisted = await this.checkUserWallet()
        if (localData.getAccessToken && !!this.investor) this.jwt = localData.getAccessToken()

        if (walletExisted) {
          if (!this.investor) {
            snackController.error('Investor is undefined')
          }
        } else {
          await this.signUp()
        }
      }
    )
  }

  @action.bound showDetailWalletDialog(value: boolean) {
    this.detailDialog = value
  }

  @action.bound showConnectWalletDialog(value: boolean) {
    this.connectDialog = value
  }

  async test() {
    // await this.blockChainHandler?.sendOptIn(this.account)
    // await this.blockChainHandler?.buy(this.account, 100000000)
  }

  @action resetCache() {
    this.jwt = ''
    this.userInfo = undefined
    this.investor = undefined
  }

  @asyncAction *start() {
    try {
      if (localData.getActiveWalletAddress) this.account = localData.getActiveWalletAddress()
      if (localData.getWalletSelected) this.selectedWallet = localData.getWalletSelected()
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @action.bound changeShowConnectDialog(value: boolean) {
    this.showConnectDialog = value
  }

  @asyncAction *connectMyAlgoWallet() {
    this.connectingWallet = true
    try {
      const myAlgoConnect = new MyAlgoConnect({ disableLedgerNano: false })
      const settings = {
        shouldSelectOneAccount: true,
        openManager: true,
      }
      const accounts = yield myAlgoConnect.connect(settings)
      if (accounts.length) {
        this.account = accounts[0]?.address
        this.selectedWallet = 'my-algo-wallet'
        localData.setWalletSelected('my-algo-wallet')
        localData.setActiveWalletAddress(this.account)
      }
      this.showConnectWalletDialog(false)
    } catch (e) {
      //
    } finally {
      this.connectingWallet = false
    }
  }

  @asyncAction *connectAlgoSigner() {
    if (typeof (window as any).AlgoSigner !== 'undefined') {
      yield (window as any).AlgoSigner.connect()
      const accounts = yield (window as any).AlgoSigner.accounts({
        ledger: 'TestNet',
      })
      if (accounts.length) {
        this.account = accounts[0]?.address
        this.selectedWallet = 'algo-signer'
        localData.setWalletSelected('algo-signer')
        localData.setActiveWalletAddress(this.account)
      }
      this.showConnectWalletDialog(false)
    } else {
      ;(window as any)
        .open('https://chrome.google.com/webstore/detail/algosigner/kmmolakhbgdlpkjkcjkebenjheonagdm', '_blank')
        .focus()
    }
  }

  @asyncAction *createSynapsSession() {
    try {
      if (!this.sessionId) {
        this.loadingKycState = true
        const res: any = yield apiService.applies.createSession({ investorId: this.investor?.id })
        this.sessionId = res?.synapsSessionId
        if (!this.synapsClient) {
          this.synapsClient = new SynapsClient(this.sessionId, 'individual')
          this.synapsClient.init()
          this.synapsClient.on('finish', async () => {
            this.investor = await apiService.investors.findOne(this.investor?.id)
          })
        }
      }
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.loadingKycState = false
    }
  }

  @asyncAction *connectViaWalletConnect() {
    try {
      if (!connector.connected) {
        connector.createSession()
      }
      connector.on('connect', (error, payload) => {
        // Subscribe to connection events
        this.account = payload?.params[0].accounts[0]
        this.selectedWallet = 'pera-wallet'
        localData.setActiveWalletAddress(this.account)
        this.showConnectWalletDialog(false)
        if (error) {
          throw error
        }
      })

      connector.on('session_update', (error, payload) => {
        if (error) {
          throw error
        }
      })

      connector.on('disconnect', (error, payload) => {
        if (error) {
          throw error
        }
        if (payload.params[0]?.message === 'Session update rejected') snackController.error('User reject request')
        else this.disconnect()
      })
    } catch (error) {
      snackController.commonError(error)
    } finally {
      //
    }
  }

  @asyncAction *signMessage() {
    try {
      const user = yield apiService.users.find({ username: this.account })
      const nonce = user[0]?.nonce
      const message = `https://algolaunch.io wants to: \n Sign message with account \n ${this.account} - One time nonce: ${nonce}`
      const encoder = new TextEncoder()
      const messageEncoded = encoder.encode(message)

      const params = yield this.algodClient?.getTransactionParams().do()
      const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        suggestedParams: {
          ...params,
        },
        from: this.account,
        to: this.account,
        amount: 0,
        note: messageEncoded,
      })

      if (this.selectedWallet === 'algo-signer') {
        const txn_b64 = (window as any).AlgoSigner.encoding.msgpackToBase64(txn.toByte())
        const signedTxs = yield (window as any).AlgoSigner.signTxn([{ txn: txn_b64 }])
        return { signedTxn: signedTxs[0].blob, publicAddress: this.account }
      } else if (this.selectedWallet === 'my-algo-wallet') {
        const myAlgoConnect = new MyAlgoConnect()
        const signedTxns = yield myAlgoConnect.signTransaction(txn.toByte())
        const signedTxn = signedTxns.blob
        const signedTxnBase64 = encode(signedTxn)
        return { signedTxn: signedTxnBase64, publicAddress: this.account, nonce }
      } else if (this.selectedWallet === 'pera-wallet') {
        const txns = [txn]
        const txnsToSign = txns.map((txn) => {
          const encodedTxn = Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString('base64')
          return {
            txn: encodedTxn,
          }
        })
        const requestParams = [txnsToSign]
        const request = formatJsonRpcRequest('algo_signTxn', requestParams)
        const deviceType = checkDeviceType()
        //Request open Pera Wallet application on mobile browser
        if (deviceType?.device?.type === 'mobile') {
          const deepLink = deviceType?.os?.name === 'iOS' ? 'algorand-wc://' : 'algorand://'
          window.location.href = deepLink
        }
        const result: Array<string | null> = yield connector.sendCustomRequest(request)
        const decodedResult = result.map((element) => {
          return element ? new Uint8Array(Buffer.from(element, 'base64')) : null
        })
        const signedTxn = encode(decodedResult[0])
        return { signedTxn: signedTxn, publicAddress: this.account, nonce }
      }
    } catch (error: any) {
      snackController.commonError(error)
    }
  }

  @asyncAction *updateUserInfo(email: string, userName: string, type: 'update' | 'register') {
    try {
      this.loading = true
      const signature = yield this.signMessage()
      let res
      if (signature)
        res = yield apiService.investors.updateUserInfo({ ...signature, walletAddress: this.account, email, userName })
      if (res) {
        if (type === 'register') snackController.success('Register successfully')
        else snackController.success('Information has been changed')
        yield appProvider.router.push('/vault')
      }
      this.checkUserWallet()
    } catch (error: any) {
      snackController.commonError(error)
    } finally {
      this.loading = false
    }
  }

  @asyncAction *signIn() {
    try {
      this.isLoadingSignIn = true
      const signature = yield this.signMessage()
      let res
      if (signature) res = yield apiService.users.signIn(signature)
      if (res) {
        this.jwt = res.jwt
        this.userInfo = res.updatedUser
        this.investor = res.updatedUser?.investor
        localData.setAccessToken(res.jwt)
      }
      return true
    } catch (error: any) {
      snackController.commonError(error)
    } finally {
      this.isLoadingSignIn = false
    }
  }

  @asyncAction *signUp() {
    try {
      yield apiService.users.signUp({ publicAddress: this.account })
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @action disconnect() {
    localData.setActiveWalletAddress('')
    localData.setWalletSelected('')
    localData.setAccessToken('')
    localData.removeWalletConnect()
    connector && connector.killSession()
    window.location.reload()
  }

  @action logout() {
    localData.setAccessToken('')
    window.location.reload()
  }

  @asyncAction *checkUserWallet() {
    try {
      const res = yield apiService.users.find({ username: this.account })
      if (res.length) {
        this.userWallet = res[0]
        this.userInfo = res[0]
        this.investor = res[0]?.investor
      }
      return !!res.length
    } catch (error: any) {
      snackController.commonError(error)
    }
  }

  @computed get isLogin() {
    return this.walletConnected && !!this.jwt
  }

  @computed get isKycPending() {
    return this.investor?.status === 'kyc-pending'
  }
  @computed get isKycFinalRejected() {
    return this.investor?.status === 'kyc-banned'
  }
  @computed get isKycVerified() {
    return this.investor?.status === 'kyc-verified'
  }

  @computed get kycStatusText() {
    if (this.isKycVerified) return { color: 'primary', text: 'Verified' }
    else if (this.isKycPending) return { color: 'error', text: 'Pending' }
    else return { color: 'error', text: 'Unverifined' }
  }

  @computed get walletConnected() {
    return !!this.account
  }

  @computed get shortAccount() {
    if (!this.account) return ''
    return this.account.substr(0, 4) + '...' + this.account.substr(this.account.length - 4)
  }

  @computed get shortAccount2() {
    if (!this.account) return ''
    return this.account.substr(0, 15) + '...' + this.account.substr(this.account.length - 8)
  }

  @computed get isRegisted() {
    return (this.userWallet as any)?.email && (this.userWallet as any)?.userName
  }

  @computed get userNameText() {
    return (this.userWallet as any)?.userName
  }

  @computed get email() {
    return (this.userWallet as any)?.email
  }
}

export const walletStore = new WalletStore()
