import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', redirect: '/vault' },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/modules/staking/pages/staking-design-system.vue'),
  },
  {
    path: '/summary',
    name: 'Summary',
    component: () => import('@/modules/summary/pages/summary.vue'),
  },
  {
    path: '/staking/design-system',
    name: 'Staking Design System',
    component: () => import('@/modules/staking/pages/staking-design-system.vue'),
  },
  {
    path: '/projects',
    name: 'Project',
    component: () => import('@/modules/project/pages/project-page.vue'),
  },
  {
    path: '/projects/:unicodeName',
    name: 'Project Detail',
    component: () => import('@/modules/project-detail/pages/project-detail.vue'),
  },
  {
    path: '/projects/:unicodeName/:refLink',
    name: 'Whitelist',
    component: () => import('../modules/apply/pages/ido-apply.vue'),
  },
  {
    path: '/allocation',
    name: 'Allocation',
    component: () => import('@/modules/allocation/pages/allocation.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/modules/register/pages/register.vue'),
  },
  {
    path: '/mypage',
    name: 'MyPage',
    component: () => import('@/modules/register/pages/mypage.vue'),
  },
  {
    path: '/staking',
    name: 'Staking',
    component: () => import('@/modules/staking/pages/staking.vue'),
  },
  {
    path: '/vault',
    name: 'Vault',
    component: () => import('@/modules/vault/pages/vault.vue'),
  },

  {
    path: '/new-project',
    name: 'NewProject',
    component: () => import('@/modules/new-project/pages/new-project.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app')?.scrollIntoView({ behavior: 'smooth' })
  },
})

export default router
