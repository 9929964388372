import Vue from 'vue'

export const componentRegister = (): void => {
  Vue.component('connect-wallet', () => import('@/components/connect-wallet.vue'))
  Vue.component('navigation-bar', () => import('@/components/navigation-bar.vue'))
  Vue.component('mobile-navigation-bar', () => import('@/components/mobile-navigation-bar.vue'))
  Vue.component('navigation-drawer', () => import('@/components/navigation-drawer.vue'))
  Vue.component('page-footer', () => import('@/components/page-footer.vue'))
  Vue.component('algo-checkbox', () => import('@/components/form/checkbox.vue'))
  Vue.component('algo-checkbox-form', () => import('@/components/form/checkbox-form.vue'))
  Vue.component('algo-text-field', () => import('@/components/form/algo-text-field.vue'))
  Vue.component('algo-textarea', () => import('@/components/form/algo-textarea.vue'))
  Vue.component('project-card', () => import('@/components/project-card/project-card.vue'))
  Vue.component('floating-button', () => import('@/components/floating-button.vue'))
  Vue.component('confirm-transaction-dialog', () => import('@/components/confirm-transaction-dialog.vue'))
}
