import { apiService } from '@/services/api-services'
import { action, computed, observable, reaction, runInAction } from 'mobx'
import moment from 'moment'
import { timer } from 'rxjs'
import VueRouter from 'vue-router'
import { walletStore } from './stores/wallet-store'

export class AppProvider {
  router!: VueRouter
  api = apiService
  wallet = walletStore

  @observable currentTime = moment()

  constructor() {
    timer(0, 1000).subscribe(() => {
      runInAction(() => {
        this.currentTime = moment().milliseconds(0)
      })
    })
  }
}

export const appProvider = new AppProvider()
