/* eslint-disable */
import Axios from 'axios'
import { PoolModel } from '@/models/pool-model'
import { InvestorModel } from '@/models/investor-model'
import { walletStore } from '@/stores/wallet-store'
export type ApiRouteType =
  | 'pools'
  | 'applies'
  | 'investors'
  | 'users'
  | 'logs'
  | 'tickets'
  | 'staking-statistics'

export type VaultApiRoute = 'notes' | 'mint-phases' | 'mint-transactions'


const axios = Axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT })
const vaultAxios = Axios.create({ baseURL: process.env.VUE_APP_VAULT_API_ENDPOINT })
export interface SignUpData {
  publicAddress: string
}

export interface SignInSignature {
  signedTxn: string
  publicAddress: string
  nonce: number
}

export interface UserInfo {
  walletAddress: string
  userName: string
  email: string
  signedTxn: string
  nonce: number
}

export class ApiHandler<T> {
  constructor(private axios: any, private route: ApiRouteType) {}

  async count(params?: any): Promise<number> {
    const res = await this.axios.get(`${this.route}/count`, { params })
    return res.data
  }

  async create(model: T): Promise<T> {
    const res = await this.axios.post(this.route, model)
    return res.data
  }

  async delete(id: any): Promise<T> {
    const res = await this.axios.delete(`${this.route}/${id}`)
    return res.data
  }

  async update(id: any, model: T): Promise<T> {
    let res: any
    if (id) {
      res = await this.axios.put(`${this.route}/${id}`, model)
    } else {
      res = await this.axios.put(`${this.route}`, model)
    }
    return res.data
  }

  async find<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:DESC', _limit: -1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const res = await this.axios.get(this.route, { params })
    const lst = res.data
    return lst
  }

  async findOne<T>(id: any): Promise<T> {
    let res: any
    if (id) {
      res = await this.axios.get(`${this.route}/${id}`)
    } else {
      res = await this.axios.get(`${this.route}`)
    }
    const result = res.data
    return result
  }
}
export class ApiHandlerJWT<T> {
  constructor(private axios: any, private route: ApiRouteType) {}

  // async find<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
  //   const settingDefault = { _sort: 'createdAt:DESC', _limit: 25, _start: 0 }
  //   params = { ...settingDefault, ...settings, ...(params ?? {}) }
  //   const res = await this.axios.get(this.route, { params })
  //   const lst = res.data
  //   return lst
  // }

  async count(params?: any): Promise<number> {
    const res = await this.axios.get(`${this.route}/count`, {
      params,
      headers: {
        ...axios.defaults.headers,
        Authorization: `Bearer ${walletStore.jwt}`,
      },
    })
    return res.data
  }

  async create(model: T): Promise<T> {
    const res = await this.axios.post(this.route, model, {
      headers: {
        ...axios.defaults.headers,
        Authorization: `Bearer ${walletStore.jwt}`,
      },
    })
    return res.data
  }
  async depositTicket(model: T): Promise<T> {
    const { investorId, poolId, amount, applyId, depositedTicketNumber } = model as any
    const res = await this.axios.post(
      'tickets/createTicket',
      { investorId, poolId, amount, applyId, depositedTicketNumber },
      {
        headers: {
          ...axios.defaults.headers,
          Authorization: `Bearer ${walletStore.jwt}`,
        },
      }
    )
    return res.data
  }

  async delete(id: any): Promise<T> {
    const res = await this.axios.delete(`${this.route}/${id}`, {
      headers: { Authorization: `Bearer ${walletStore.jwt}` },
    })
    return res.data
  }
  async find<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:DESC', _limit: -1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const res = await this.axios.get(this.route, {
      params,
      headers: {
        ...axios.defaults.headers,
        // Authorization: `Bearer ${walletStore.jwt}`,
      },
    })
    const lst = res.data
    return lst
  }

  async findOne<T>(id: any): Promise<T> {
    let res: any
    if (id) {
      res = await this.axios.get(`${this.route}/${id}`, { headers: { Authorization: `Bearer ${walletStore.jwt}` } })
    } else {
      res = await this.axios.get(`${this.route}`, { headers: { Authorization: `Bearer ${walletStore.jwt}` } })
    }
    const result = res.data
    return result
  }

  async update(id: any, model?: any): Promise<T> {
    let res: any
    if (id) {
      res = await this.axios.put(`${this.route}/${id}`, model, {
        headers: { Authorization: `Bearer ${walletStore.jwt}` },
      })
    } else {
      res = await this.axios.put(`${this.route}`, model, { headers: { Authorization: `Bearer ${walletStore.jwt}` } })
    }
    return res.data
  }

  async signUp(signupData: SignUpData) {
    const res = await this.axios.post(`auth/local/register`, signupData)
    return res.data
  }

  async signIn(signature: SignInSignature) {
    const res = await this.axios.post(`auth/signin`, signature)
    return res.data
  }

  async createSession(model) {
    const { investorId } = model
    const res = await this.axios.post(
      `investors/create-session`,
      { investorId },
      { headers: { Authorization: `Bearer ${walletStore.jwt}` } }
    )
    return res.data
  }
  async createApply(model) {
    const res = await this.axios.post(`applies/create-apply`, model, {
      headers: { Authorization: `Bearer ${walletStore.jwt}` },
    })
    return res.data
  }
  async applyStakingTickets(model) {
    const res = await this.axios.post(`applies/apply-staking-tickets`, model, {
      headers: { Authorization: `Bearer ${walletStore.jwt}` },
    })
    return res.data
  }

  async checkKycAddresss(params) {
    const res = await axios.post(`investors/check-kyc-address`, params, {
      headers: { Authorization: `Bearer ${walletStore.jwt}` },
    })
    return res.data
  }

  async updateUserInfo(data: UserInfo) {
    const res = await axios.post(`update-user-information`, data)
    return res.data
  }
}

export class VaultHandler<T> {
  constructor(private axios: any, private route: VaultApiRoute) {}
  async count(params?: any): Promise<number> {
    const res = await this.axios.get(`${this.route}/count`, {
      params,
      headers: {
        ...axios.defaults.headers,
      },
    })
    return res.data
  }

  async find<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:DESC', _limit: -1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const res = await this.axios.get(this.route, {
      params,
      headers: {
        ...vaultAxios.defaults.headers,
        // Authorization: `Bearer ${walletStore.jwt}`,
      },
    })
    const lst = res.data
    return lst
  }

  async create(model: T): Promise<T> {
    const res = await this.axios.post(this.route, model, {
      headers: {
        ...axios.defaults.headers,
      },
    })
    return res.data
  }

  async createNFTMetadata(data: { assetID: number; mintType: string; walletAddress: string; distribute: string, index: number }) {
    const res = await vaultAxios.post(`notes/create-nft-metadata`, data)
    return res.data
  }
}
export class ApiService {
  pools = new ApiHandler<PoolModel>(axios, 'pools')
  users = new ApiHandlerJWT<any>(axios, 'users')
  applies = new ApiHandlerJWT<any>(axios, 'applies')
  investors = new ApiHandlerJWT<InvestorModel>(axios, 'investors')
  logs = new ApiHandlerJWT<any>(axios, 'logs')
  tickets = new ApiHandlerJWT<any>(axios, 'tickets')
  stakingStatistics = new ApiHandler<any>(axios, 'staking-statistics')
  noteNfts = new VaultHandler<any>(vaultAxios, 'notes')
  mintingPhases = new VaultHandler<any>(vaultAxios, 'mint-phases')
  mintTransaction = new VaultHandler<any>(vaultAxios, 'mint-transactions')
}

export const apiService = new ApiService()
