import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import { componentRegister } from './plugins/component-register'
import router from './router'
import vuetify from './plugins/vuetify'
import { vueFilterRegister } from './plugins/vue-filter-register'
import { pluginsRegister } from './plugins/plugins-register'
// import VueAnalytics from 'vue-analytics'

Vue.config.productionTip = false
componentRegister()
vueFilterRegister()
pluginsRegister()

// Vue.use(VueAnalytics, {
//   id: 'G-0C08J4KQCP',
//   router,
// })

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
